<template>
  <v-card v-if="task !== null" style="background-color: white; margin: 0 auto">
    <v-card-title>詳細</v-card-title>
    <v-card-actions class="d-flex justify-end pa-2">
      <v-btn
        icon
        @click="$router.push(`/main/task/update?taskId=${task.task_id}`)"
      >
        <v-icon size="20px">mdi-pencil-outline</v-icon>
      </v-btn>
    </v-card-actions>
    <v-simple-table style="background-color: white; margin: 0 auto">
      <tbody>
        <section>
          <ul>
            <li class="my-1">
              <v-text-field
                :value="task.task_name"
                label="タスク名"
                dense
                outlined
                color="primary"
                readonly
              ></v-text-field>
            </li>
            <li class="my-3">
              <v-textarea
                :value="task.task_detail"
                label="詳細"
                dense
                outlined
                color="primary"
                readonly
              ></v-textarea>
            </li>
            <!-- <li v-else class="mb-3">
              <fieldset class="detail"  @click="detailActive=!detailActive"  v-click-outside="hideDetailActive" :class='{active:detailActive}'>
                <legend class="detail_legend">詳細</legend>
                <div>
                  <UrlToLink>{{ task.task_detail }}</UrlToLink>
                </div>
              </fieldset>
            </li> -->
            <li class="mb-3">
              <v-textarea
                :value="task.task_comment"
                label="コメント"
                dense
                outlined
                color="primary"
                readonly
              ></v-textarea>
            </li>
          </ul>
          <ul class="d-flex align-center mb-3">
            <li style="width: 180px">
              <v-text-field
                :value="task.task_date_start"
                label="開始日"
                prepend-inner-icon="mdi-calendar"
                color="primary"
                readonly
                outlined
                dense
              ></v-text-field>
            </li>
            <li>~</li>
            <li style="width: 180px; margin-right: 13px">
              <v-text-field
                :value="task.task_date_end"
                label="期限日"
                prepend-inner-icon="mdi-calendar"
                color="primary"
                readonly
                outlined
                dense
              ></v-text-field>
            </li>
            <li class="mr-3" style="width: 180px">
              <v-text-field
                :value="task.task_predicted_hours"
                label="予定時間(hours)"
                prepend-inner-icon="mdi-clock-time-nine-outline"
                readonly
                outlined
                dense
                color="primary"
              ></v-text-field>
            </li>
            <li class="mr-3" style="width: 180px">
              <v-text-field
                :value="task.task_actual_hours"
                label="実際の時間(hours)"
                prepend-inner-icon="mdi-clock-time-nine"
                readonly
                outlined
                dense
                color="primary"
              ></v-text-field>
            </li>
          </ul>
        </section>
        <section>
          <ul class="d-flex mb-2">
            <li class="mr-3" style="width: 180px">
              <v-select
                :value="task.task_category_id"
                :items="formCategories"
                label="案件名"
                item-value="category_id"
                item-text="category_name"
                prepend-inner-icon="mdi-shape"
                outlined
                dense
                color="primary"
                readonly
              ></v-select>
            </li>
            <li class="mr-3" style="width: 180px">
              <v-select
                :value="task.task_requested_user_id"
                :items="formUserData"
                label="担当者"
                item-value="id"
                item-text="name"
                prepend-inner-icon="mdi-account"
                outlined
                dense
                color="primary"
                readonly
              ></v-select>
            </li>
            <li class="mr-3" style="width: 180px">
              <v-select
                :value="task.task_requester_user_id"
                :items="formUserRequesterData"
                label="依頼者"
                item-value="id"
                item-text="name"
                prepend-inner-icon="mdi-account"
                outlined
                dense
                color="primary"
                readonly
              ></v-select>
            </li>
            <li v-if="task.task_requester_user_id === -1" class="mr-3">
              <v-text-field
                label="依頼者記入"
                :value="task.task_requester_user_name"
                dense
                outlined
                color="primary"
                readonly
              ></v-text-field>
            </li>
          </ul>
          <ul>
            <li class="d-flex align-center my-4">
              <p class="mr-2">状態&emsp;：</p>
              <v-radio-group
                :value="task.task_status"
                color="primary"
                :column="false"
                readonly
              >
                <v-radio
                  v-for="status in SELECTSTA"
                  :value="status.value"
                  :label="status.label"
                  :key="status.value"
                ></v-radio>
              </v-radio-group>
            </li>
            <li class="d-flex align-center my-4">
              <p class="mr-2">優先度：</p>
              <v-radio-group
                :value="task.task_priority"
                color="primary"
                :column="false"
                readonly
              >
                <v-radio
                  v-for="priority in SELECTPRI"
                  :value="priority.value"
                  :label="priority.label"
                  :key="priority.value"
                ></v-radio>
              </v-radio-group>
            </li>
          </ul>
        </section>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
import { SELECTPRI } from "@/plugins/const";
import { SELECTSTA } from "@/plugins/const";
// import UrlToLink from "@/components/UrlToLink.vue";
// import ClickOutside from 'vue-click-outside';

export default {
  name: "MyTaskDetail",
  props: ["task"],
  // components: {
  //   UrlToLink,
  // },
  // directives: {
  //   ClickOutside,
  // },
  data: () => ({
    SELECTPRI: SELECTPRI,
    SELECTSTA: SELECTSTA,
    detailActive: false,
  }),
  computed: {
    formCategories() {
      return [
        {
          category_id: 0,
          category_name: "全て",
        },
      ].concat(this.$store.state.categories);
    },
    formUserData() {
      return [
        {
          id: 0,
          name: "全て",
        },
      ].concat(this.$store.state.users);
    },
    formUserRequesterData() {
      return [
        {
          id: -1,
          name: "取引先",
        },
        {
          id: 0,
          name: "全て",
        },
      ].concat(this.$store.state.users);
    },
  },
  methods: {
    hideDetailActive() {
      if (this.detailActive) this.detailActive = false
    },
  },
  async mounted() {
    await this.$store.dispatch("getUsers");
    this.$store.dispatch("getCategories");
    this.$store.dispatch("getTags");
  },
};
</script>

<style lang="scss" scoped>
.detail_legend {
  font-size: 13px;
  padding: 0 3px;
  color:rgba(0, 0, 0, 0.6);
  line-height: 13px;
}
.detail {
  overflow: auto;
  max-width: 100%;
  min-height: 32px;
  height: 113px;
  line-height: 1.75rem;
  margin: 0px 0.5px;
  padding: 0px 8px 15px 7px;
  width: calc(100% - 1px);
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  white-space: pre-wrap;
  align-items: center;
  div{
    padding-left: 2px;
  }
}
.detail:hover {
  border: 1px solid rgba(0, 0, 0, 0.87);
}
.detail.active {
  border: 2px solid #1976d2;
  margin-left: 0px;
  padding-left: 6.5px;
  width: 100%;
  div{
    padding-left: 2px;
  }
  .detail_legend{
    color:#1976d2;
  }
}
::v-deep {
  .v-input--radio-group__input .red--text {
    .theme--light.v-label {
      color: red;
    }
  }
  .v-input--radio-group__input .orange--text {
    .theme--light.v-label {
      color: orange;
    }
  }
  .v-input--radio-group__input .light-green--text {
    .theme--light.v-label {
      color: yellowgreen;
    }
  }
  .v-input--radio-group__input .green--text {
    .theme--light.v-label {
      color: green;
    }
  }
  .v-input--radio-group__input .blue--text {
    .theme--light.v-label {
      color: blue;
    }
  }
  .v-input--radio-group__input .amber--text {
    .theme--light.v-label {
      color: #ffc107;
    }
  }
}
</style>
